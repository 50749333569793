const hostname = window.location.hostname

const servidorOficial = !((hostname === 'localhost') || (hostname === '127.0.0.1') || (hostname.startsWith('teste')))

const urlServer = (servidorOficial ? 'https://server.sistemaespiga.com.br/' : 'https://teste.server.sistemaespiga.com.br/')

const consts = {
    numVersao: '1.6.6',
    servidorOficial: servidorOficial,
    urlAPI: urlServer,
    linkRepositorio: urlServer + 'repositorio/',
    linkApp: '/#',
    apiGoogleKey: 'AIzaSyD6Atx8Wf79hNLxOYjkwhhFgZmT8j0SoTY',
    classificacaoPessoa: [
        { nome: "cls_cliente", label: "Cliente" },
        { nome: "cls_fornecedor", label: "Fornecedor" },
        { nome: "cls_produtor", label: "Produtor" },
        { nome: "cls_armazem", label: "Armazém" },
        { nome: "cls_transportadora", label: "Transportadora" },
        { nome: "cls_monitor", label: "Monitor" },
        { nome: "cls_usuario", label: "Usuário" },
        { nome: "cls_familiar", label: "Familiar" },
    ],
    estados: [
        { cod_uf: 12, uf: 'AC' },
        { cod_uf: 27, uf: 'AL' },
        { cod_uf: 13, uf: 'AM' },
        { cod_uf: 16, uf: 'AP' },
        { cod_uf: 29, uf: 'BA' },
        { cod_uf: 23, uf: 'CE' },
        { cod_uf: 53, uf: 'DF' },
        { cod_uf: 32, uf: 'ES' },
        { cod_uf: 52, uf: 'GO' },
        { cod_uf: 21, uf: 'MA' },
        { cod_uf: 31, uf: 'MG' },
        { cod_uf: 50, uf: 'MS' },
        { cod_uf: 51, uf: 'MT' },
        { cod_uf: 15, uf: 'PA' },
        { cod_uf: 25, uf: 'PB' },
        { cod_uf: 26, uf: 'PE' },
        { cod_uf: 22, uf: 'PI' },
        { cod_uf: 41, uf: 'PR' },
        { cod_uf: 33, uf: 'RJ' },
        { cod_uf: 24, uf: 'RN' },
        { cod_uf: 11, uf: 'RO' },
        { cod_uf: 14, uf: 'RR' },
        { cod_uf: 43, uf: 'RS' },
        { cod_uf: 42, uf: 'SC' },
        { cod_uf: 28, uf: 'SE' },
        { cod_uf: 35, uf: 'SP' },
        { cod_uf: 17, uf: 'TO' },
    ],
    opcoesSimNao: [
        { texto: 'Sim', valor: '1' },
        { texto: 'Não', valor: '0' },
    ], 
    opcoesNiveis: [
        { valor: '0', texto: '0' },
        { valor: '1', texto: '1' },
        { valor: '2', texto: '2' },
        { valor: '3', texto: '3' },
        { valor: '4', texto: '4' },
        { valor: '5', texto: '5' },
        { valor: '6', texto: '6' },
        { valor: '7', texto: '7' },
        { valor: '8', texto: '8' },
        { valor: '9', texto: '9' },
    ],
    opcoesNatureza: [
        { valor: 'C', texto: 'Crédito' },
        { valor: 'D', texto: 'Débito' },
    ],
    opcoesTipoRateio: [
        { valor: 'A', texto: 'Analítico' },
        { valor: 'S', texto: 'Sintético' },
    ],
    opcoesAtivo: [
        { valor: '1', texto: 'Ativo' },
        { valor: '0', texto: 'Inativo' },
    ],
    primaryColor: '#162718'
}

export default consts

export const toastifyConfig = {
    position: "top-right", 
    autoClose: 3000, 
    hideProgressBar: false, 
    newestOnTop: false, 
    closeOnClick: true,
    rtl: false, 
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "colored", 
}