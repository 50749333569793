export function trimCampos(campos) {
    Object.keys(campos).forEach((key) => {
        if (typeof (campos[key]) === 'string') {
            campos[key] = campos[key].trim()
        } else if ((typeof (campos[key]) === 'object') && (campos[key] !== null)) {
            //Array de campos
            if(campos[key].length){
                campos[key].forEach((obj, index) => {
                    if (!obj instanceof File) { //Verifica se não é arquivo (somente leitura)
                        campos[key][index] = trimCampos(obj)
                    }
                })
            } else {
                //Sub objeto
                Object.keys(campos[key]).forEach((k) => {
                    if (typeof (campos[key][k]) === 'string') {
                        campos[key][k] = campos[key][k].trim()
                    }
                })
            }
        }
    });

    return campos
}

export function montaFormData(campos, nomeCampo, form = new FormData()){
    Object.keys(campos).forEach((key) => {
        //Verifica se não é arquivo
        if (!key.includes('_file')) {
            if (typeof (campos[key]) !== 'object') {
                if (typeof (campos[key]) !== 'boolean') {
                    form.append((!nomeCampo ? key : `${nomeCampo}[${key}]`), campos[key])
                } else {
                    form.append((!nomeCampo ? key : `${nomeCampo}[${key}]`), (campos[key] === true ? "1" : "0"))
                }
            } else {
                if (campos[key] !== null) {
                    campos[key].forEach((obj, index) => {
                        form = montaFormData(obj, `${key}[${index}]`, form)
                    })
                }
            }
        } else {
            campos[key].forEach((obj, index) => {
                form.append(`${(!nomeCampo ? key : `${nomeCampo}[${key}]`)}${(campos[key].length > 1 ? '_' + index : '')}`, obj)
            })
        }
    })

    return form
}

export function formatMoeda(valor) {
    return valor.toLocaleString('en-US', { 'style': 'currency', 'currency': 'USD' }).replace('$', '').replace(',', '').replace(".", ",");
}

export function formataCPF(cpf = ""){
    if(cpf !== "")
        return cpf.substring(0, 3) + "." + cpf.substring(3, 6) + "." + cpf.substring(6, 9) + "-" + cpf.substring(9)
    else
        return cpf
}

export function retiraCaracteres(string = ""){
    return string.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').replaceAll(',', '')
}